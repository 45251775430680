import React, { useState, useEffect, useCallback, useRef } from 'react';
import styles from './style.module.css';
import moment from 'moment-timezone';
import { getWhatsappHistory, getBotDetails } from '../../services/kollectaApi';

const WhatsappHistory = ({ bot, cellphone }) => {
    const [messages, setMessages] = useState([]);
    const [loading, setLoading] = useState(false);
    const [validatedBot, setValidatedBot] = useState(null);
    const chatContainerRef = useRef(null);
    const formRef = useRef(null);

    const formatPhoneNumber = (cellphone) => {
        let cleaned = cellphone.replace(/\D/g, '');
        
        if (/^(\d{2})9(\d{8})$/.test(cleaned)) {
            cleaned = cleaned.replace(/^(\d{2})9(\d{8})$/, '55$1$2');
        } else if (/^55(\d{2})9(\d{8})$/.test(cleaned)) {
            cleaned = cleaned.replace(/^55(\d{2})9(\d{8})$/, '55$1$2');
        }

        if (cleaned.startsWith('55') && cleaned.length === 13) {
            return cleaned;
        }
    
        if (cleaned.startsWith('55') && (cleaned.length === 12 || cleaned.length === 14)) {
            return cleaned;
        }
    
        return cleaned;
    };

    const validateBot = useCallback(async () => {
        const user = JSON.parse(localStorage.getItem('user'));
        if (user && user.companyId) {
            const data = {
                companyId: user.companyId,
                botName: bot,
            };

            try {
                const response = await getBotDetails(data?.companyId);

                if (response.data) {
                    console.log('BOT: ', response?.data[0].name);
                    setValidatedBot(response?.data[0].name || bot);
                } else {
                    setValidatedBot(bot);
                }
            } catch (error) {
                console.error('Erro ao validar bot:', error);
                setValidatedBot(bot);
            }
        } else {
            setValidatedBot(bot);
        }
    }, [bot]);

    const loadMessageHistory = useCallback(async () => {
        if (!validatedBot) return;

        setLoading(true);
        try {
            const formattedPhone = formatPhoneNumber(cellphone);
            console.log('%c formattedPhone', 'color: #bada55', formattedPhone);
            const response = await getWhatsappHistory(validatedBot, formattedPhone);
            setMessages(response.data.reverse());
        } catch (error) {
            console.error('Erro ao carregar histórico de mensagens:', error);
        } finally {
            setLoading(false);
        }
    }, [validatedBot, cellphone]);

    const scrollToBottom = () => {
        if (chatContainerRef.current) {
            setTimeout(() => {
                if (chatContainerRef.current) {
                    chatContainerRef.current.scrollTop = chatContainerRef.current.scrollHeight;
                }
            }, 100);
        }
    };

    const updatePaddingBottom = () => {
        if (chatContainerRef.current && formRef.current) {
            chatContainerRef.current.style.paddingBottom = `${formRef.current.offsetHeight}px`;
        }
    };

    useEffect(() => {
        validateBot();
    }, [validateBot]);

    useEffect(() => {
        if (validatedBot) {
            loadMessageHistory();
        }
    }, [validatedBot, loadMessageHistory]);

    useEffect(() => {
        if (messages.length > 0) {
            scrollToBottom();
            updatePaddingBottom();
        }
    }, [messages]);

    useEffect(() => {
        const handleResize = () => {
            updatePaddingBottom();
        };

        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    if (loading) {
        return <div>Carregando...</div>;
    }

    if (!messages || messages.length === 0) {
        return <div>Sem histórico de conversas para esse número</div>;
    }

    return (
        <div className={styles.container}>
            <div ref={chatContainerRef} id="chatContainer" className={styles.chatContainer}>
                {messages.map((message, index) => (
                    <div
                        key={index}
                        className={`${styles.response} ${message.type === 'question' ? styles.userMessage : styles.assistantMessage}`}
                        style={{ alignSelf: message.type === 'question' ? 'flex-end' : 'flex-start' }}
                    >
                        <div className={styles.messageText}>
                            {message.type === 'question' ? (
                                message.text
                            ) : (
                                <div dangerouslySetInnerHTML={{ __html: message.text }} />
                            )}
                        </div>
                        <div className={styles.messageDate}>
                            {message.createdAt && moment(message.createdAt).format('HH:mm DD/MM')}
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default WhatsappHistory;
