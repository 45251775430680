import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import styles from './style.module.css';
import { applySpec, pipe, pathOr, reduce } from 'ramda';
import eye from '../../assets/images/eye.svg';
import ReactGA from 'react-ga';
import { useNavigate } from 'react-router-dom';
import DropdownMenu from '../DropdownMenu';

const Table = ({
  title,
  downloadText,
  tbSettings,
  data,
  handleButtonClick,
  user
}) => {
  const [receivedData, setReceivedData] = useState(data);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const navigate = useNavigate();

  const handleCellClick = (item) => {
    ReactGA.event({
      category: 'Botão',
      action: 'Clique',
      label: 'Modal de informações devedor'
    });
    setSelectedItem(item);
    if (item !== null) {
      navigate('/dashboard/details', { state: { data: item } });
    }
  }
  
  const buildItem = (acc, next) => {
    if (!acc[next.path]) {
      acc = {
        ...acc,
        [next.path]: (item) => {
          if (!item) {
            return (
              <td key={next.path} className={styles.tbCel}>
                -
              </td>
            );
          }

          if (next.path === 'days') {
            if (item.status === 'paid') {
              return (
                <td key={`${next.path}-paid`} className={styles.tbCel}>
                  -
                </td>
              );
            }
  
            const dueDate = item.validDueDate ? new Date(item.validDueDate) : null;
            const today = new Date();
            let overdueDays = null;
  
            if (dueDate && dueDate < today) {
              const timeDiff = today - dueDate;
              overdueDays = Math.floor(timeDiff / (1000 * 60 * 60 * 24));
            }
  
            return (
              <td key={`${next.path}-${overdueDays}`} className={styles.tbCel}>
                {overdueDays !== null ? `${overdueDays} dias` : '-'}
              </td>
            );
          }

          if (next.path === 'details') {
            return (
              <td key={`${next.path}-details`} className={styles.details}>
                <img
                  onClick={() => handleCellClick(item)}
                  src={eye}
                  alt="Botão para página de detalhe"
                />
              </td>
            );
          }

          const value = next.columnFormat
            ? next.columnFormat(item)
            : pathOr('-', [next.path], item);
  
          return (
            <td
              key={`${next.path}-${value}`}
              className={classNames([
                styles.tbCel,
                { [styles.tbAlignCenter]: next.center },
                next.path === 'details' ? styles.cursor : '',
              ])}
              onClick={next.path === 'details' ? () => handleCellClick(item) : null}
            >
              {value}
            </td>
          );
        },
      };
    }
    return acc;
  };
  
  const buildTdItem = (item) => {
    let items = []
    for(let key in item) {
      items = [...items, item[key]]
    }
    return items
  }
  
  const buildCollumn = (settings, receivedData) => {
    const paths = pipe(reduce(buildItem, {}))(settings);
    const result =  receivedData
    .map(applySpec(paths))
    .map(buildTdItem);
      return result;
  }

  const buildDataTb = buildCollumn(tbSettings, receivedData);

  const RenderHeaders = ({ headerName, center, path }, index) => {
    if (path === 'details') {
      return (
        <th
          key={`${headerName}-${index}`}
          className={classNames([
            styles.tbCel,
            { [styles.tbAlignCenter]: center },
          ])}
        >
         {user && user?.type !== 'operator' && <DropdownMenu queueFunction={handleButtonClick} />}
        </th>
      );
    }
  
    return (
      <th 
        key={`${headerName}-${index}`}
        className={classNames([
          styles.tbCel,
          { [styles.tbAlignCenter]: center },
        ])}
      >
        {headerName}
      </th>
    );
  }

  useEffect(() => {
    setReceivedData(data);
  }, [data, receivedData]);
 
  return (
    <div className={styles.tableContainer}>
      <table className={styles.tableCustom}>
        <thead className={styles.tbHeader}>
          <tr className={handleButtonClick && styles.containerWithButton}>
            {tbSettings.map(RenderHeaders)}
          </tr>
        </thead>
        <tbody>
          {buildDataTb.map((item, index) => (
            <tr key={index}>
              {item}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  )
}

export default Table;